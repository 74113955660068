import cls from "./products.module.scss";
import Link from "next/link";
import React, { FC } from "react";
import {CheckToastSvg, FailSvg, HeartSvg, WishCircle} from "../../../svg";
import {useToast} from "../../../atoms/Toast/useToast";
import {domainUrl} from "../../../helper";
import {getUpOrCrossProd} from "../../../store/cart";
import {useDispatch, useSelector} from "react-redux";
import {IGlobalProduct, IState} from "../../../types/state";
import {setCrossValid, setPopupName, setTempData} from "../../../store/general";
import {IBlockProductProps} from "./BlockProducts";
import {useRouter} from "next/router";
import {IProduct} from "../../../types/homePageTypes";
import {wishlistAddItem, wishlistRemoveItem} from "../../../store/wishlist";
import {handleAddCardClickWithSellProducts} from "../../shared/ProductCard/helpers";

interface IProductListProps extends Omit<IBlockProductProps, "products"> {
    product: IProduct;
    priority?: boolean
}

const ProductCard: FC<IProductListProps> = (
    {
        product,
        dbName,
        translationsData,
        backOrderValue,
        selectedCurrItems: {
            selectedCurrCode,
            selectedCurrRate,
            selectedCurrSymbol
        },
        selectedLocale,
        priority,
        loading = "lazy",
    }
) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const {push: routerPush} = useRouter();
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer);
    const wishlist = useSelector((state: IState) => state.wishlist)
    const isProductInWishList = wishlist?.find((item: IGlobalProduct) => item.id === product.id)
    const signed = useSelector((state: IState) => state.customer.authenticated);
    const {
        qty: [productQuantity = 0],
        id: productId,
        flats: [{
            has_up_sell = 0,
            has_cross_sell = 0,
            url_key = "",
            name: productName = "",
            short_description: productShortDesc = "",
            price: productPrice = "",
            special_price: productSpecialPrice = ""
        } = {}] = [],
        images: [imagePath] = []
    } = product;
    const isProductInStock = productQuantity > 0 || !!parseFloat(backOrderValue)
    const toastOpenHandle: Record<string, Record<string, () => void>> = {
        "true": {
            "true": () => toast.open(
                <span className="d-flex chek-fms">
                    <FailSvg/>
                    {translationsData.ProductNotAvailable}
                </span>
            ),
            "false": () => toastOpenHandle["true"]["true"]()
        },
        "false": {
            "true": () => toast.open(
                <span className="d-flex chek-fms">
                    <CheckToastSvg/>
                    {translationsData.ToastAddToCart}
               </span>
            ),
            "false": () => {
            }
        }
    }
    const upCrossSellHandle: Record<string, Record<string, () => void>> = {
        "true": {
            "true": () => {
                dispatch(setPopupName("upsell"));
                dispatch(getUpOrCrossProd(
                    productId,
                    'up-sell',
                    selectedLocale,
                    selectedCurrCode,
                    1,
                    {exchange_rate: {rate: selectedCurrRate}, symbol: selectedCurrSymbol, code: selectedCurrCode}
                ))
                dispatch(setTempData([product]))
            },
            "false": () => upCrossSellHandle["true"]["true"](),
        },
        "false": {
            "true": () => {
                dispatch(getUpOrCrossProd(
                    productId,
                    'cross-sell',
                    selectedLocale,
                    selectedCurrCode,
                    1,
                    {exchange_rate: {rate: selectedCurrRate}, symbol: selectedCurrSymbol, code: selectedCurrCode}
                ))
                dispatch(setCrossValid(true));
                dispatch(setPopupName("crossSell"));
                dispatch(setTempData([product]))
            },
            "false": () => {
            }
        }
    }
    const addToast = (error?: boolean) => {
        return toastOpenHandle[`${error}`][`${isProductInStock}`]()
    }
    const openUpCrossProd = () => {
        return upCrossSellHandle[`${!!parseFloat(has_up_sell)}`][`${!!parseFloat(has_cross_sell)}`]()
    }
    const cartDataObj = {
        product,
        quantity: 1,
        cartToken,
        customer: customer as { token: string },
        selectedLocale,
        selectedCurrCode,
        optionsObject: null,
        addToast
    }

    const handlePrice = {
        true: {
            true: <div className={cls.prices}>
                <div className={cls.new_price}>
                    <div className={cls.currency__value}>
                        <span>{selectedCurrSymbol}</span>
                        <span>{Number(productSpecialPrice)}</span>
                    </div>
                </div>
                <div className={cls.old_price}>
                    <div className={cls.currency__value}>
                        <span>{selectedCurrSymbol}</span>
                        <span>{Number(productSpecialPrice)}</span>
                    </div>
                </div>
            </div>,
            false: <div className={cls.prices}>
                <div className={cls.old_price}>
                    <div className={cls.currency__value}>
                        <span>{selectedCurrSymbol}</span>
                        <span>{Number(productPrice).toFixed(2)}</span>
                    </div>
                </div>
                <div className={cls.new_price}>
                    <div className={cls.currency__value}>
                        <span>{selectedCurrSymbol}</span>
                        <span>{Number(productSpecialPrice).toFixed(2)}</span>
                    </div>
                </div>
            </div>
        },
        false: {
            true: <div className={cls.prices}>
                <div className={cls.currency__value}>
                    <span>{selectedCurrSymbol}</span>
                    <span>{Number(productPrice)}</span>
                </div>
            </div>,
            false: <div className={cls.prices}>
                <div className={cls.currency__value}>
                    <span>{selectedCurrSymbol}</span>
                    <span>{Number(productPrice).toFixed(2)}</span>
                </div>
            </div>
        }
    }
    const handleWishIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        if (!signed) {
            toast.open(
                <span className="d-flex faild-toast-fms">
                    <FailSvg/>
                    {translationsData.ToastSignIn}
                </span>
            )
        } else {
            !!isProductInWishList ?
                dispatch(wishlistRemoveItem(product.id))
                : dispatch(wishlistAddItem(product, selectedLocale))
            const toastChildren = {
                "true": {svg: <FailSvg/>, title: translationsData?.ToastWishListRemove},
                "false": {svg: <CheckToastSvg/>, title: translationsData?.ToastWishList}
            }
            toast.open(
                <span className="d-flex faild-toast-fms">
                    {toastChildren[`${!!isProductInWishList}`].svg}
                    {toastChildren[`${!!isProductInWishList}`].title}
                </span>
            )
        }
    }

    return (
        <div className={cls["product-card"]}>
            <div className={`${cls["product-card__image"]}`}>
                <Link href={`/${url_key}`} prefetch={priority}>
                    <div className={`${cls["product-image__body"]} ${cls["product-image__body-fms"]}`}>
                        <div className={`${cls["item_overlay"]} ${cls["hide-for-tablet"]}`}/>
                        <div className={cls["product-card__buttons"]}>
                            <div
                                onClick={handleWishIconClick}
                                className={`btn-light btn-svg-icon btn-svg-icon--fake-svg ${cls["product-card__wishlist"]}`}
                            >
                                <div className={cls["wishlist-icon-wrapper"]}>
                                    <HeartSvg className={isProductInWishList ? cls["inner-wishlist"] : ""}/>
                                </div>
                            </div>
                        </div>
                        <div className={cls["img_btn_wrapper"]}>
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAddCardClickWithSellProducts(
                                        openUpCrossProd, dispatch, cartDataObj, routerPush, url_key
                                    )[`${isProductInStock}`]
                                        [`${!!parseFloat(has_up_sell)}`]
                                        [`${!!parseFloat(has_cross_sell)}`]();
                                }}
                                className={`btn btn-primary ${cls[`product-card__addToCart`]} ${cls["hide-for-tablet"]}`}
                            >
                                {translationsData.AddToCartButtonTitle}
                            </button>
                        </div>
                        <div className={cls["img_wrapper"]}>
                            <img
                                src={domainUrl(`${dbName}${imagePath}`)}
                                alt="product card image"
                                loading={loading as "eager" | "lazy"}
                                width="280"
                                height="280"
                            />
                        </div>
                    </div>
                </Link>
            </div>
            <div className={cls["product-card__info"]}>
                <div className={cls["product-card__name"]}>
                    <Link href={`/${url_key}`} prefetch={priority}>
                        {productName}
                    </Link>
                </div>
                <div className={cls["product-card-description"]}>
                    <span dangerouslySetInnerHTML={{__html: productShortDesc}}/>
                </div>
            </div>
            <div className={cls["product-card__actions"]}>
                <div className={cls["product-card__availability-mobile"]}>
                    <div className={cls["product-card__availability"]}>
                        {translationsData.ProductAvailable}
                        <span className="text-success">
                            {translationsData.ProductInStock}
                        </span>
                    </div>
                    {handlePrice[`${!!productSpecialPrice}`][`${selectedCurrCode === "AMD"}`]}
                </div>
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        handleAddCardClickWithSellProducts(
                            openUpCrossProd, dispatch, cartDataObj, routerPush, url_key
                        )[`${isProductInStock}`]
                            [`${!!parseFloat(has_up_sell)}`]
                            [`${!!parseFloat(has_cross_sell)}`]();
                    }}
                    className={`btn btn-primary ${cls[`product-card__addToCart`]} ${cls["hide-for-desktop"]}`}
                >
                    {translationsData.AddToCartButtonTitle}
                </button>
            </div>
        </div>
    )
}

export default ProductCard;